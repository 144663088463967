import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Aid extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>AID</title>
            <meta name="description" 
              content="Work page for my AID project" 
            />
            <meta name="robots" content="index, follow" />
        </Helmet>
        <div>
          <h1>AID</h1>
          <p><strong>Purpose:</strong>A platform aimed at helping people easily donate to disaster relief efforts without worrying about which organization they should choose.</p>
          <p><strong>Duration:</strong> 10 Weeks</p>
          <p><strong>Role:</strong> UX/UI Design, Usability Testing, Research</p>
          <a href="https://xd.adobe.com/view/63e891d3-9b0a-49d3-55c3-e81e74b42396-15d9/">Mobile Prototype </a>
          <a href="https://xd.adobe.com/view/548d7b0a-2504-4cd3-789d-4c49a8651238-22bc/">Desktop Prototype</a>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Identifying and Researching the problem</h2>
            <p>
              The problem my group and I were trying to solve was having so many places to give 
              aid for disaster relief when a natural disaster happens. Many places open up donations 
              to give aid, but it is difficult to easily tell which organization is trustworthy and 
              actually helps provide aid.
            </p>
            <p>
              We knew that we wanted to make a service that would make donating easier, but before 
              moving forward with finding more about potential users, we researched the problem to 
              clarify that the problem does exist and that any existing solutions do not solve the 
              problem. 
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
              <h2 className="my-3">User Research and Usability Testing</h2>
              <p>
                In order to get a sense of who the main users were, we conducted interviews with a 
                wide range of people,  from college students to grandparents. We found that older 
                people were more likely to donate towards causes. 
              </p>
              <p>
                For all of our users, convenience and speed were a problem when they wanted to donate. 
                To get around that, we wanted to incorporate features into our service that would help 
                users be able to quickly donate while also knowing that their money will be used wisely.
              </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Designing and Brainstorming</h2>
            <p>
              In creating our solution, we decided to make a platform focused on being transparent 
              and trustworthy. It would be a part of the federal government to make people have more 
              trust in it. Users would be able to choose where they want their money to go, such as 
              food, clothing, or shelter. 
            </p>
            <p>
              Based on our research, we noted that the audience for this type of service is wide-ranging 
              so we decided to put the service on multiple platforms: mobile, desktop, and paper.
            </p>
            <p>
              The paper form of our service was specifically made because many of the older people we 
              interviewed still donated through the mail. They were also the ones that donated the most.
            </p>
            <p>
              After deciding on what the solution would include, we created low fidelity prototypes of 
              the website, app, and paper form, which includes a newsletter and donation form.
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="my-5">
          <img className="smallScreen" src={require("../img/aid_lofi.png")} alt="A low fidelity mock-up of the checkout screen of AID."/>
          <img className="smallScreen" src={require("../img/aid_news.png")} alt="A mock-up of what the physical newsletter would look like."/>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Designing Our Solution</h2>
            <p>
              After creating the low fidelity prototypes, we tasked each member to work on different parts 
              of the app. I was tasked with the mobile version of the app, of which there are some screens 
              below.
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="my-5">
          <img className="smallScreen" src={require("../img/aid_phone1.png")} alt="A screen showing the about us page on the mobile prototype."/>
          <img className="smallScreen" src={require("../img/aid_phone2.png")} alt="A screen showing the thank you page on the mobile prototype."/>
          <img className="smallScreen" src={require("../img/aid_phone3.png")} alt="A screen showing the homepage on the mobile prototype."/>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Next Steps and What I Learned</h2>
            <p>
              After working on this project, I would like to consider the business aspects of the app more 
              and would like to see if it is actually feasible. Something like this integrated with the 
              government would have a lot of legislation to go through, but it would be interesting to know.
            </p>
            <p>
              More improvement could be done with informing users of new disasters, how much money is needed 
              for each disaster, and an ETA of how long repairs will be. Given the opportunity, I would have 
              liked to focus on improving the lasting impact a donation can have on the user and the people 
              affected by the donation.
            </p>
            <p>
              This was one of my first group projects centered around creating a solution to solve a problem, so I
              learned a lot about the design processes and the different steps that go into UX. Researching the problem to 
              make sure that it is an actual problem for your users, empathizing with users, and creating a solution 
              that is accessible to all users were all important things that I learned. 
            </p>
          </div>
          <div className="space"></div>
        </div>
      </div>
    );
  }
}

export default Aid;
