import React, { Component } from 'react';
import { NavLink} from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { fadeInDown } from 'react-animations';

const FadeDown = styled.div`animation: 0.6s ${keyframes `${fadeInDown}`}`;

class Home extends Component {
  render() {
    return (
      <div className="intro-container fade">
      	<FadeDown>
          <div className="header">
            <div className="headerWrapper">
              <p className="home-name">Hi, I'm Carmelito!</p>
              <p className="home mt-1">I'm a user researcher, developer, and designer.</p>
              <p className="home mt-1">Currently looking for new opportunities and companies to grow with.</p>
            </div>
          </div>
        </FadeDown>
        
        {/*Project Content*/}
        <div className="case-div">
          <h2 className="mb-2">Case Studies</h2>
        </div>
        <ul className="project-container mt-5">
          <div className="project">
            <div className="project-item-container shadow">
              <div className="project-item">
                <NavLink to="/amazon">
                  <img src={require("./img/amzcover.png")} alt="amazon cover" className="project-image amz-image"/>
                </NavLink>
              </div>
              <div className="project-text">
                <NavLink to="/amazon">
                  <p className="project-title">Amazon</p>
                  <p className="project-info mt-3">UX Research</p>
                </NavLink>
              </div>
            </div>
          </div>
          
          <div className="project">
            <div className="project-item-container shadow">
              <div className="project-item">
                <NavLink to="/amplify">
                  <img src={require("./img/advcover.png")} alt="cover for amplify" className="project-image adv-image"/>
                </NavLink>
              </div>
              <div className="project-text">
                <NavLink to="/amplify">
                  <p className="project-title">Amplify</p>
                  <p className="project-info mt-3">UX Design | UX Research</p>
                </NavLink>
              </div>
            </div>
          </div>
          
          <div className="project">
            <div className="project-item-container shadow">
              <div className="project-item">
                <NavLink to="/travelbud">
                  <img src={require("./img/travelcover.jpg")} alt="cover for travelbud project" className="project-image tra-image"/>
                </NavLink>
              </div>
              <div className="project-text">
                <NavLink to="/travelbud">
                  <p className="project-title">Travelbud, a voice assistant</p>
                  <p className="project-info mt-3">UX Design | VUI Design</p>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="project">
            <div className="project-item-container shadow">
              <div className="project-item">
                <NavLink to="/solstice">
                  <img src={require("./img/solbig2.png")} alt="cover for solstice project" className="project-image sol-image"/>
                </NavLink>
              </div>
              <div className="project-text">
                <NavLink to="/solstice">
                  <p className="project-title">Solstice, an OS centered around mindfulness</p>
                  <p className="project-info mt-3">UX Design</p>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="project">
            <div className="project-item-container shadow">
              <div className="project-item">
                <NavLink to="/projectblock">
                  <img src={require("./img/blocknew.png")} alt="cover for project block" className="project-image blo-image"/>
                </NavLink>
              </div>
              <div className="project-text">
                <NavLink to="/projectblock">
                  <p className="project-title">Project Block</p>
                  <p className="project-info mt-3">Front End Development | UX Design</p>
                </NavLink>
              </div>
            </div>
          </div>

        </ul>
      	
      	</div>
      
    );
  }
}

export default Home;
