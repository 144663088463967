import React, { Component } from 'react';
import './style.css';
import { Route, Switch, NavLink} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Home.js';
import About from './About.js';
import Study from './projects/Study.js';
import Explorer from './projects/Explorer.js';
import Aid from './projects/Aid.js';
import Block from './projects/Block.js';
import Challenge from './projects/Challenge.js';
import Solstice from './projects/Solstice.js';
import TravelBud from './projects/TravelBud.js';
import Advbiofeedback from './projects/Advbiofeedback.js';
import Amazon from './projects/Amazon.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      class: ""
    };
  }

  renderMenu = () => {
    this.setState({isOpen: true, class: "fade"});   
  }

  closeMenu = () => {
    this.setState({isOpen: false, class: ""});   
  }

  render() {
    let year = new Date().getFullYear();
    if (this.state.isOpen) {
      return (
        <div className="overall-container">
          <div className= {this.state.class + " mt-5"}>
            <Helmet>
              <title>Carmelito Gutierrez</title>
              <meta name="description" 
                content="I'm Carmelito, a student in Seattle interested in UX Design.
                I'm an Informatics major at the University of Washington with a focus 
                on Human-Computer Interaction" 
              />
              <meta name="robots" content="index, follow" />
            </Helmet>
            <img src={require("./img/cross.png")} className="mobileCloseButton" alt="Exit button" onClick={this.closeMenu}/>
            <div>
              <ul>
                <li className="m-nav-item">
                  <NavLink to="/" className="nav-link" onClick={this.closeMenu} href="">Work</NavLink>
                </li>
                <li className="m-nav-item">
                  <a className="nav-link" rel="noopener noreferrer" onClick={this.closeMenu} href="https://drive.google.com/file/d/1KE9LsGCm-3U8-AW0V8AV_b_qPK77y9PC/view?usp=sharing" target="_blank">Resume</a>
                </li>
                <li className="m-nav-item">
                  <NavLink to="/about" className="nav-link" onClick={this.closeMenu} href="">About</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="overall-container">
          <div className="fade">
            <Helmet>
              <title>Carmelito Gutierrez</title>
              <meta name="description" 
                content="I'm Carmelito, a student in Seattle interested in UX Design.
                I'm an Informatics major at the University of Washington with a focus 
                on Human-Computer Interaction" 
              />
              <meta name="robots" content="index, follow" />
            </Helmet>
            <img src={require("./img/bars.png")} className="mobileMenuButton d-block d-md-none mb-5" alt="three bars" onClick={this.renderMenu}/>
            <div className="mt-5 fade navigation d-none d-md-block">
                <ul className="nav justify-content-end">
                  <li className="nav-item">
                    <NavLink to="/" className="nav-link" href="#">Work</NavLink>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" rel="noopener noreferrer" href="https://drive.google.com/file/d/1KE9LsGCm-3U8-AW0V8AV_b_qPK77y9PC/view?usp=sharing" target="_blank">Resume</a>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/about" className="nav-link" href="#">About</NavLink>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" rel="noopener noreferrer" href="https://www.linkedin.com/in/carmelitogutierrez/" target="_blank">LinkedIn</a>
                  </li>
                </ul> 
            </div>     
            <Switch>
              <Route exact path='/' component={Home}/>
              <Route exact path='/about' component={About}/>
              <Route exact path='/studybud' component={Study}/>
              <Route exact path='/explorer' component={Explorer}/>
              <Route exact path='/aid' component={Aid}/>
              <Route exact path='/projectblock' component={Block}/>
              <Route exact path='/challenges' component={Challenge}/>
              <Route exact path='/solstice' component={Solstice}/>
              <Route exact path='/travelbud' component={TravelBud}/>
              <Route exact path='/amplify' component={Advbiofeedback}/>
              <Route exact path='/amazon' component={Amazon}/>
            </Switch>
            <div className="links d-flex justify-content-center mt-5">
              <p><a target="_blank" href="mailto:carmelitogutierrez@gmail.com" rel="noopener noreferrer">Email</a></p>
              <p><a target="_blank" href="https://github.com/CarameIMacchiato" rel="noopener noreferrer">Github</a></p>
            </div>
            <footer>
              <p>&copy; {year} Carmelito Gutierrez. Made with React ❤️</p>
            </footer>
          </div>
        </div>
      );
    }
  }
}

export default App;
