import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Block extends Component {
  render() {
    return (
      <div className="work-container">
        <Helmet>
            <title>Block</title>
            <meta name="description" 
              content="Work page for Project Block" 
            />
            <meta name="robots" content="index, follow" />
        </Helmet>
        <div>
          <h1>Project Block</h1>
          <blockquote className="proj-quote">
            Project Block is a website made to help people get over their artist's block by having them play or make choose your own adventure games.
            <div className="mt-1 info">
              <br></br>Duration: 10 Weeks
              <br className="mt-2"></br>Role: UX Design, UI Design, Front-end Development
              <br></br><a href="https://project-block-11.firebaseapp.com/">Link to Project Block</a>
            </div>
          </blockquote>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Why create the site?</h2>
            <p>
              This project began as an individual project, and later during the school quarter, people were paired up in groups of two or more people.
            </p>
            <p>
              As part of this project, we were told to think of a problem that we could help solve by creating a website.
              The problem I focused on was artist's block. I did a little research to see how widespread the problem is and what people usually do to combat artist's block. 
            </p>
            <p>
              Many of the solutions I found were not technology based, such as reading a book, taking a break, trying to find inspiration somewhere, or talking to others.
              Online solutions seemed to be random generators, like random plot generators and random name generators. I had used them before and I liked the idea of it
              so that is the solution I went with.
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="align-items-center">
          <div className="my-5">
            <h2 className="my-3">Designing the Website</h2> 
            <p>
              These were the top three ideas I had:
            </p>
            <p>
              <ul>
                <li className="list-unstyled">Random Prompt Generator</li>
                <li className="list-unstyled">Choose your own adventure game</li>
                <li className="list-unstyled">Forum to talk with others</li>
              </ul>
            </p>
            <p>
              I decided to remove a forum in the list because it would not actively solve the problem and there are many forums that already exists, as well as social media.
            </p>
            <p>
              In the end, I combined the idea of the game and random generators to have choose your own adventure games that generated
              a random ending and random color palette depending on your choices.
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="my-5">
          <p>Below are mockups of what I initially wanted the website to look like at first.</p>
          <div className="img-container-travel">
            <img className="flex-img-travel smallScreen mr-2" src={require("../img/blockmockup1.jpg")} alt="A mockup of the block title screen, showing a play button and options with a navbar on top"/>
            <img className="flex-img-travel smallScreen" src={require("../img/blockmockup2.jpg")} alt="A mockup of an example story, showing the choices a player can pick"/>
          </div>
        </div>
        <div className="align-items-center">
          <div className="my-5">
            <h2 className="my-3">Adding Features and Continuing Work</h2>
            <p>
              After building the first iteration of the website with HTML/CSS and Javascript, people were assigned partners or groups
              to work on recreating the entire website in React.js.  
            </p>
            <p>   
              When assigned to groups, the team members had to choose one member's project to continue to work on as they could not
              all be worked on.
              After grouping up, the team I was in ended up choosing to continue with my project and to expand upon it. 
            </p>
            <p>
              With the help of Firebase, we decided to add these main features to improve the experience of the website.
            </p>
            <h3 className="mt-5">Saving Prompts & Palettes</h3>
            <p>
              A feature we wanted to add that would make using the website have a better experience is saving the prompts and palettes 
              that the user has generated. This would allow the user to access their past prompts and palettes in case they wanted to 
              view them again.
            </p>
            <div className="center-container">
              <img className="smallScreen mt-3" src={require("../img/block_saveprompts.PNG")} alt="The screen where user prompts are saved, showing two example prompts."/>
            </div>
            <h3 className="mt-5">Enable the Creation and Hosting of User-Generated Stories</h3>
            <p>
              The original idea we had was to just have there be one expansive story with mutliple endings for people to play, but
              we decided to expand the website to allow users to create their own games and play through a database of user generated stories.
              This would allow users to give back to the community and perhaps allow them to be creative in different ways, helping
              them get over their artist's block.
            </p>
            <div className="center-container">
              <img className="smallScreen mt-3" src={require("../img/block_storycreate.PNG")} alt="Screenshot showing the area where the user can create stories."/>
            </div>
          </div>
          <div className="space"></div>
        </div>
        <div className="align-items-center">
          <div className="my-5">
            <h2 className="my-3">Next Steps and What I Learned</h2>
            <p className="mt-3">
              If I were to continue to work on this project, I would like improve the experience for users when they create
              stories. The story generation form right now is dynamically generated, and we did not have enough time to look for ways to better 
              implement methods that let the user know when they added a story path or ending. Fleshing out other experiences such as the 
              account page and the prompts and palettes page would make the experience feel a lot better as well.
            </p>
            <p className="mt-5">
              Doing this project gave me a lot of experience with front end development and creating designs from mockups. 
              This is what I learned from doing this project:
            </p>
            <p className="mt-4 ml-4">
              <ul>
                <li>Working with React, HTML, and CSS</li>
                <li>How to act as both a designer and developer on a project</li>
                <li>Assign tasks appropriately to each team member</li>
                <li>How to go from design mockups to a working prototype</li>
              </ul>
            </p>
          </div>
          <div className="space"></div>
        </div>
      </div>
    );
  }
}

export default Block;
