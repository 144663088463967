import 'bootstrap/dist/css/bootstrap.css';
import './style.css';
import React from 'react';
import firebase from 'firebase/app';
import ReactDOM from 'react-dom';
import App from './App';
import ScrollToTop from './ScrollToTop.js';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
  apiKey: "AIzaSyCbJnv2GOsXXHyInGvoM06tIiPa5HbwMlc",
  authDomain: "portfolio-31b1b.firebaseapp.com",
  databaseURL: "https://portfolio-31b1b.firebaseio.com",
  projectId: "portfolio-31b1b",
  storageBucket: "portfolio-31b1b.appspot.com",
  messagingSenderId: "1041806906912",
  appId: "1:1041806906912:web:4d862dd09a65fdd3"
};

// Render the app
ReactDOM.render(
	<BrowserRouter>
		<ScrollToTop>
			<App />
		</ScrollToTop>
	</BrowserRouter>, 
	document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
