import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Study extends Component {
  render() {
    return (
      <div className="work-container">
        <Helmet>
            <title>Study Bud</title>
            <meta name="description" 
              content="Work page for Study Bud" 
            />
            <meta name="robots" content="index, follow" />
        </Helmet>
        <div>
          <h1>Study Bud</h1>
          <blockquote className="proj-quote">
            Study Bud is a website made to help lonely college students find study partners and friends from their classes.
            <div className="mt-1 info">
              <br></br>Duration: 10 Weeks
              <br className="mt-2"></br>Role: UX Design, UI Design, Usability Testing, Research
              <br></br><a href="https://drive.google.com/file/d/1XpNqh_psrC6wl1j6GPoIGpfbfsru8yxB/view?usp=sharing">Design Specification </a>
              <br></br>
              <a href="https://invis.io/KSPB4LQAW3N"> Invision Prototype</a>
            </div>
          </blockquote>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5 ">
            <h2 className="my-3">Identifying the problem</h2>
              <p>
                Our team decided to tackle the problem of loneliness in college because we all
                experienced the problems of finding people to study with or be friends with. We
                knew this would be a tough problem to try and solve but we felt as if we could offer
                a way to make navigating the problem easier. 
              </p> 
          </div>
          <div className="space"></div>
        </div>
        <div className="align-items-center">
          <div className="my-5 ">
            <h2 className="my-3">Research</h2>
            <p>
              Before we began to work on any sort of solution, we wanted to make sure we understood 
              our problem so that we could tackle the key issues behind loneliness. We performed a 
              literature review and we also made sure to research any existing solutions that could 
              solve this problem. 
            </p>
            <p className="mt-4">
              These were the most important things we found:
            </p>
            <p>
              <ul>
              <li className="list-unstyled">Students that are out of state or from another country often have problems with loneliness.</li>
              <li className="list-unstyled">In general, mental health problems for college students are rising.</li>
              </ul>
            </p>
            <p className="mt-4">We decided that our main stakeholder group would 
            be international students, but our solution would still be available for anyone 
            else that struggles with loneliness.</p>

            <h3 className="mt-5">Interviews</h3>
            <p>To ensure that we were heading in the right direction, we conducted 
            interviews with people that fit into our userbase. We interviewed 2 
            international students and 1 local student to find out how they found people to study 
            with normally and what would make them more likely to use services that helped them find
            study partners.</p>
            <p>This is what we found valuable in forming our solution:</p>
            <p>
              <ul className="mt-4">
                <li className="list-unstyled">They mostly met people through their classes</li>
                <li className="list-unstyled">They stay in contact with people that are in their major or have similar goals</li>
                <li className="list-unstyled">They value studying with others and in groups</li>
              </ul>
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="align-items-center">
          <div className="my-5">
            <h2 className="my-3">Designing and Brainstorming</h2>
            <p>
              After identifying the problem and conducting research, we moved forward with thinking about potential 
              solutions that we could design. We brainstormed different possible features that 
              we wanted to include in a solution and whether we wanted to make an app, website, 
              or other form of solution.
            </p>
            <p>
              We did brainstorms for the final features we wanted to design and a 
              diagram of the user flow of the process of choosing a partner.
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="img-container-travel">
          <img className="smallScreen flex-img-travel" src={require("../img/study_features.jpg")} alt="Whiteboard of study bud features"/>
          <img className="smallScreen flex-img-travel ml-2" src={require("../img/study_features2.jpg")} alt="Second Whiteboard of study bud features"/>
        </div>
        <div className="align-items-center">
          <div className="my-5">
            <h2 className="my-3">Our Solution</h2>
            <p>
              For our solution, we decided to build a website that interacts with Canvas. 
              Canvas is a Learning Management System utilized by almost all of the classes at UW. 
              It has a record of a student's classes and schedule. We decided to pair our solution 
              with Canvas because of how widely used it is on campus and it would make registration for 
              users easier.
            </p>
            <p className="mt-5">These are the features we decided on for Study Bud:</p>
            <p>
              <ul>
                <li className="list-unstyled">School emails are required to make access to the site safer and for students only</li>
                <li className="list-unstyled">Users can use Canvas to import their schedules to make matches</li>
                <li className="list-unstyled">Users will get matched based on their shared classes, interests, the way they learn, and their background</li>
                <li className="list-unstyled">The website allows users to chat with matches and get new matches at any time</li>
                <li className="list-unstyled">There are widgets available to the users to make chatting and arranging meeting times easier</li>
              </ul>
            </p>
            <h3 className="mt-5">Wireframing</h3>
            <p>
              I quickly sketched what some of the screens would look like on paper to get everyone
              on the same page about what the solution could possibly look like.
            </p>
            <div className="center-container">
              <img className="smallScreen mt-5" src={require("../img/homepage_register.png")} alt="paper sketches of study bud screens"/>
            </div>
          </div>
          <div className="space"></div>
        </div>
        <div className="align-items-center">
          <div className="my-5">
            <h2 className="my-3">Usability Testing</h2>
            <p>
              After creating wireframes, we began to work on a high fidelity prototype. Once the 
              prototype was finished, I conducted usability tests with two 
              people that fit into our main stakeholder group. They were assigned a task to 
              complete with no guidance, which was to navigate the website and make a match.  
            </p>
            <p>
              Our testers didn't encounter any major problems with the user flow as the tasks were completed, but there was feedback 
              to make some quality of life changes to make things easier for users:
            </p>
            <p className="mt-3">
              <ul>
                <li className="list-unstyled">Make it clearer how schedules are imported, so say it's importing from Canvas</li>
                <li className="list-unstyled">New match notifications are too instrusive</li>
              </ul>
            </p>
            <p>The pictures below show how the notifications changed after hearing the feedback.</p>
            <div className="center-container">
              <img className="smallScreen mt-5" src={require("../img/old_way.png")} alt="old notification showing a modal on screen"/>
            </div>
            <p className="text-center">The old notification with a modal</p>
            <div className="center-container">
              <img className="smallScreen mt-5" src={require("../img/new_way.png")} alt="new notification updating the timeline"/>
            </div>
            <p className="text-center">New less intrusive notification</p>
          </div>
          <div className="space"></div>
        </div>
        <div className="align-items-center">
          <div className="my-5">
            <h2 className="my-3">Next Steps and What I Learned</h2>
            <p>If we were given the opportunity to continue working on this project, my group and 
            I would have liked to refine the matching system and the chatting features. Those could 
            be improved to work better and provide a better experience. The home page could also use 
            more content and better links to the different functions of the website.</p>
            <p className="mt-3">Something that we talked about was having a feature that could possibly help people 
            that suffer from more than just loneliness, such as anxiety or depression. As they would
            be more likely to not finish things, the website could give them an automatic match, 
            phrased in a way that makes it look like their match is the one that initiated contact. </p>
            <p className="mt-2">
              <ul>
                <li>How to incorporate empathy in design</li>
                <li>Do research first before making assumptions</li>
                <li>How to perform user testing</li>
              </ul>
            </p>
          </div>
          <div className="space"></div>
        </div>
      </div>
    );
  }
}

export default Study;
