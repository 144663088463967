import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Advbiofeedback extends Component {
  render() {
    return (
      <div>
        <div className="cover-image adv-cover-image">
          <h1 className="work-title">Amplify</h1>
        </div>
        <div className="work-container">
          <Helmet>
              <title>Amplify</title>
              <meta name="description" 
                content="Work page for Amplify" 
              />
              <meta name="robots" content="index, follow" />
          </Helmet>
          <div>
            <blockquote className="proj-quote">
              <div className="mb-3 info">
                <br></br>Duration: 5 months
                <br className="mt-2"></br>Role: UX Research & Design Intern
                <br></br><a href="https://www.adventurebiofeedback.com">Website</a>
                <br></br><a href="">Working Product (To Be Posted)</a>
              </div>
              Amplify is a <a href="https://www.westcoastctip.org/">CTIP</a> funded product made to help speech language pathologists perform remote therapy with their patients by providing them with tools to record audio, organize recordings, and take notes remotely.<br></br>
              <br></br>This was a remote internship and I worked together with another UX Intern to research problems that SLPs (speech language pathologists) encounter when working remotely and we also created prototypes for to help them 
              conduct therapy, specifically for children.
            </blockquote>
            
          </div>

          <div className="d-flex align-items-center">
            <div className="my-5">
              <h2 className="my-3">What's the problem?</h2>
              <p>
                To begin this project, we wanted to research the problem space and find out what difficulties SLPs have with teletherapy and current tools for recording patient utterances.
                To do this, we emailed local SLPs to schedule phone interviews, where we would ask how they currently performed therapy with children, what problems they faced with keeping the child's attention,
                and what they needed to help them perform their jobs.
              </p>

              <p className="mt-4">
                Once the COVID-19 pandemic began and stay at home orders were issued, we switched our questions to include how they were doing remote therapy and what new problems they faced. This was because 
                the product might actually help solve some problems they were facing as it was meant to be used remotely or in person.
              </p>

              <h2 className="mt-5">Interviewing SLPs</h2>
              <p>
                We heard back from a few SLPS and interviewed 7 of them to find out what problems they were facing. We did interviews because neither I nor my partner had a background in
                speech language pathology so a conversation where they could explain their situations for as long as they wanted was the best way for us to empathize with them, the end users.
              </p>

              <div className="quote-container mt-5 mb-5">
                <div className="w-50">
                    <blockquote className="center">
                      "[The problem is] getting parents to help and keeping kids occupied.”
                    </blockquote>
                    <p className="quote-source">
                      <p>Jessica A., SLP</p>
                    </p>
                </div>
              </div>

              <p className="mt-4">
                These were our key findings from our interviews:
              </p>
              <p className="ml-3">
                <ol className="text-left">
                  <li>
                    Children are very hard to perform therapy with as they are easily distracted
                  </li>
                  <li>
                    Parental involvement in speech therapy is very important for the child's progress
                  </li>
                  <li>
                    The SLPs often took notes manually or recorded audio with third party apps, so organizing everything was difficult
                  </li>
                </ol>
              </p>

              <p className="mt-5">
                Once we completed our initial interviews, my partner and I discussed our findings and decided how we should move forward. Many of the SLPs found that a tool that 
                had these features would help: 
                <ul className="text-left">
                  <li>Record audio</li>
                  <li>Organize recordings</li>
                  <li>Take notes and export them with the recordings</li>
                </ul>
                
              </p>

              <p className="mt-5">
                As we continued our interviews, we asked specific questions about what information they needed to keep track of and what information would be valuable for them to see.
              </p>


            </div>
            <div className="space"></div>
          </div>

          <div className="align-items-center">
            <div className="my-5">
              <h2>Creating Wireframes and Early Prototypes</h2>
              
              <h3 className="mt-5">Wireframes</h3>
              <p className="mt-1">
                Before moving onto designing with Figma, we first wanted to sketch out wireframes on paper to get a feeling of what we wanted to make.
              </p>

              <div className="center-container">
                <img className="my-3 image-100 shadow" src={require("../img/wire1.png")} alt="first wireframe"/>
              </div>

              <div className="center-container">
                <img className="my-3 image-100 shadow" src={require("../img/wire2.png")} alt="first wireframe"/>
              </div>

              <p className="mt-5">
                As we got more info from our interviews, we created prototypes on Figma.
                We made sure to try different solutions with each iteration of our designs to see what would work.
              </p>

              <h3 className="mt-5">First Prototypes</h3>
              <p className="mt-1">
                After our first few interviews, we wanted to try and create simple prototypes with the requirements we were given from our stakeholder.
                Doing so allowed us to get early feedback and clarification on the product from our supervisors.
              </p>

              <p className="mt-5">
                We were designing a single page application that only handled the recording and note taking process for the SLP.
                These were the prototypes we made from our earlier sketches.
              </p>

              <div className="center-container">
                <img className="my-3 image-150 shadow" src={require("../img/advproto2.png")} alt="second prototype"/>
              </div>
              <div className="center-container">
                <img className="my-3 image-150 shadow" src={require("../img/advproto3.png")} alt="third prototype"/>
              </div>
              
            </div>
            
          </div>

          <div className="align-items-center">
            <div className="my-5">
              <h2>Testing with SLPs</h2>
              <p>
                We wanted to test our prototypes with actual SLPs to gauge their reactions and get their thoughts.
                We tested two different prototypes before testing our final design.
              </p>

              <div className="center-container">
                <img className="my-3 image-150 shadow" src={require("../img/advproto35.png")} alt=" prototype version 3.5"/>
              </div>

              <p className="mt-5">
                We learned a lot from our user testing sessions and the SLPs gave valuable feedback about what types of notes they wanted to take, what they needed to see when recording, and more.
                that fueled that helped with creating the user flow and visuals.
              </p>
              
              <h3 className="mt-5">Supervisor Feedback</h3>
              <p className="mt-1">
                In addition to getting feedback from the SLPs, we also got feedback from our supervisor, who was also doing his own research with other SLPs.
                We got a lot of advice about what we were designing as well as suggestions for features that could help, which led to this version of the prototype.
              </p>
              <div className="center-container">
                <img className="my-3 image-150 shadow" src={require("../img/advproto4.png")} alt="prototype version 4"/>
              </div>
              
            </div>
          </div>

          <div className="align-items-center">
            <div className="my-5">
              <h2>Creating a Working Prototype</h2>
              <p>
                Once we were nearing the end of the project, my partner and I went back to design the final look of the product, 
                making sure to incorporate all the feedback we got.
              </p>

              <p className="mt-5">
                This was the wireframe we quickly made and iterated over before moving on to creating it in HTML.
                There were some big changes made compared to the previous prototypes, which is mainly that each recording does not have its own
                waveform. The waveform at the top will respond to any of the recordings when they are played instead.
              </p>

              <div className="center-container">
                <img className="my-3 image-150 shadow" src={require("../img/advprotofinal.png")} alt="final prototype version"/>
              </div>

              <p className="mt-5">
                And this is the end result of our work and what we turned in to our supervisors. We created everything in HTML, including the basic interactions with Javascript.
                Many changes that we did when implementing the design was changing colors to indicate different areas and finalizing buttons and features.
              </p>

              <div className="center-container">
                <img className="my-3 image-150 shadow" src={require("../img/advhtml.PNG")} alt="conversion from design to website"/>
              </div>
              
            </div>
            <div className="space"></div>
          </div>
          
          <div className="align-items-center">
            <div className="my-5">
              <h2 className="my-3">Project Next Steps & Lessons Learned</h2>
              <p className="mt-3">
                At the time this is being written, the project is still being continued, and the prototype that my partner and I made is being connected to the backend.
                Once it is done, a link will be posted to the actual web page where the project will be hosted.
              </p>
              <p className="mt-3">
                This was my first internship and the longest project I've worked on, so I really learned how iterative design can be and how many times you can go back to the
                drawing board to think of new and better designs. These are the skills I learned and reinforced from this internship: 
              </p>
              <p className="mt-4 ml-4">
                <ul className="text-left">
                  <li>Working with a team remotely</li>
                  <li>Reinforced my qualitative research skills with interviews</li>
                  <li>Working with developers</li>
                  <li>Reinforced web development skills</li>
                </ul>
              </p>
            </div>
            <div className="space"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Advbiofeedback;
