import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Amazon extends Component {
  render() {
    return (
      <div>
        <div className="cover-image amz-cover-image">
          <h1 className="work-title">Amazon One</h1>
        </div>
        <div className="work-container">
          <Helmet>
              <title>Amazon One</title>
              <meta name="description" 
                content="Work page for Amazon projects" 
              />
              <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <div>
            <blockquote className="proj-quote">
              <div className="mb-3 info">
                <br></br>Duration: Since March 2021
                <br className="mt-2"></br>Role: Operations Researcher | Support Engineer
                <br></br><a href="https://one.amazon.com">Amazon One Website</a>
              </div>
              Amazon One is a biometric reader that takes a user's unique palm print and uses it for payment, entry, and identity. 
              <br></br>
              <br></br>
              I am currently working as a researcher within the Ops team at Amazon One where I manage a queue of operations related tasks and projects that help improve the user experience and optimize my team's time.
            </blockquote>
            
          </div>

          <div className="d-flex align-items-center">
            <div className="my-5">
              <h2 className="my-3">Want to learn more?</h2>
              <p>
                I'm always happy to speak more about this role and what I'm doing. If you'd like to know more feel free to <a target="_blank" href="mailto:carmelitogutierrez@gmail.com" rel="noopener noreferrer">email me!</a>
              </p>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Amazon;
