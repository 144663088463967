import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class About extends Component {
  render() {
    return (
      <div className="about-container">
        <Helmet>
            <title>Carmelito Gutierrez | About</title>
            <meta name="description" 
              content="My about page in my portfolio" 
            />
            <meta name="robots" content="index, follow" />
        </Helmet>
        <div className="aboutHeader">
      	  <div className="aboutHeaderWrapper">
      	    <h1>About Me</h1>
      	    <p>
              Hi there! I'm a UX Designer based in Seattle. I graduated from the 
              University of Washington with a Bachelor's in Informatics with a focus on Human-Computer Interaction in 2020.
              While growing up, I have always always been interested in design and how things work. I've made art, 
              animations, video games, and more, and the design process behind each thing is always different and unique, which is why 
              I want to be a designer.
            </p>
            <p className="mt-3">
              I'm particularly interested in designing to improve lives and impact them in positive ways, especially if it helps BIPOC and other vulnerable and underserved groups. 
              I enjoy using a human-centered design perspective when designing to ensure that the end user's problems
              are taken into perspective.
            </p>
      	  </div>
      	</div>
      </div>
    );
  }
}

export default About;
