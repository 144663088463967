import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Challenge extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>Design Challenge</title>
            <meta name="description" 
              content="Work page for my design challenges" 
            />
            <meta name="robots" content="index, follow" />
        </Helmet>
        <div className="d-flex">
          <div>
            <h1>Design Challenge: User Interface Design</h1>
            <p>
              <strong>Purpose:</strong> This design challenge was done to apply to IGN's Code Foo internship.
              This particular challenge involved redesigning a page on IGN that I thought could use an improvement.
              The page I decided to redesign was the eSports page.
            </p>
            <p><strong>Role:</strong> UI Design</p>
          </div>
          <div className="space"></div>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Finding The Page to Redesign</h2>
            <p>
              When beginning this challenge, I wanted to get a feel of the website at first so I browsed through all of the main pages.
              Doing this helped me see the different parts of the website and see the different page designs that IGN had, of which I 
              found multiple. 
            </p>
            <p>
              The home page had a design that was reused on all their main pages, such as their reviews. There were wiki pages/guides about games which 
              looked different, and then there were other pages that seemed to be custom made. One such page is their shows page. There was also the eSports page,
              which looked different from the rest of the website, both in terms of content and design. 
            </p>
            <p>
              It was because the eSports page was so different that I chose to redesign it. I felt that it did not need to look so different
              from the other pages because it felt rather jarring and could be confusing to users. The layout of the articles also did not 
              make sense to me as there was no discernible method I could see for how they sorted them.
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Design Process</h2>
            <p>
              My main goal in redesigning the eSports page was to make it look more like the rest of the website because I thought that the website
              already did a good job of designing their pages. What was challenging was choosing which parts of the page to keep or scrap because 
              they were specifically there for eSports. In the end, I chose to keep most of the features, such as the schedule viewer, but redesign 
              them to make them fit the website better. 
            </p>
            <p>
              This was my end result, with my goal being to redesign the page so that it did not look dramatically different from the rest
              of the website and also to provide actual legibility to the user before they click on eSports articles to read.
            </p>
          </div>
          <div className="space"></div>
        </div>
        <div className="my-5">
          <img className="smallChallenge" src={require("../img/challenge_home.png")} alt="A mockup of the redesigned eSports homepage styled after the regular IGN pages."/>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">My Solution</h2>
            <p>
              I focused on maintaining the brand identity that IGN has already created for their website while also keeping the eSports specific tools on the page.
              One thing I kept was the major events yearly schedule and the event viewer as they included links to the eSport streams. I kept it there while also 
              moving the news stories into its own column so that users could see the main two features of the page. 
            </p>
          </div>
          <div className="space"></div>
        </div>
      </div>
    );
  }
}

export default Challenge;
