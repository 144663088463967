import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Explorer extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>Explorer</title>
            <meta name="description" 
              content="Work page for my explorer hackathon project" 
            />
            <meta name="robots" content="index, follow" />
        </Helmet>
        <div>
          <h1>DubHacks Hackathon: Explorer App</h1>
          <p><strong>Purpose:</strong> For this UX-centered hackathon, we were 
          tasked to create an app to promote exploration in the US national 
          parks and other sites. We were told to keep in mind that people that 
          had never done anything in the parks or traveled before should be 
          able to pick up the app and use it.
          </p>
          <p><strong>Duration:</strong> ~3 hours</p>
          <p><strong>Role:</strong> UX/UI Design</p>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Brainstorming Ideas</h2>
            <p>
              To begin, I chose to focus on just two activities, road trips and 
              hiking, so that the scope of the design would not be too big.
            </p>
            <p>
              I first thought about how a newcomer would feel when trying the 
              app and what would make them more likely to use the app in the 
              first place. In order to make a new user feel more welcome, 
              I decided to make my app focus on planning trips and giving tips 
              to ensure that users are prepared.
            </p>
            <p>
              When brainstorming different types of solutions, I came up with three types of landing pages as they are the first thing users see. 
            </p>
            <ul className="workBullet mt-2 pl-5">
              <li>
                A menu-based home page with card-based navigation that took users to different pages. 
              </li>
              <li>
                A map-based menu for users to immediately see what was available around them geographically.
              </li>
              <li>
                A combination of a map-based and menu-based home page.
              </li>
            </ul>
            <img className="smallScreen" src={require("../img/explore_ideas.jpg")} alt="Sketches of the different menu ideas described above."/>
          </div>
          <div className="space"></div>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Wireframing a Solution</h2>
            <p>
              I decided to combine both a menu and a map for the main menu to provide 
              a choice for people that want to quickly use the map or navigate a menu. 
              I was inspired by the design of Google Maps as I find it really easy and 
              intuitive to use. 
            </p>
            <p>
              Due to not being able to look up or perform user research, I instead 
              relied on established conventions when making decisions.
            </p>
            <p>
              Below are the lo-fi prototypes I sketched out to put my ideas on paper 
              and visualize how they could work.
            </p>
            <div className="my-5">
              <img className="smallScreen" src={require("../img/explore_lofi1.jpg")} alt="Sketches of the different features: the main menu and item page."/>
              <img className="smallScreen" src={require("../img/explore_lofi2.jpg")} alt="Sketches of the different features, such as the quizzes and planning pages."/>
            </div>
            <h3 className="my-3">Features</h3>
            <ul className="features">
              <li>
                <p><strong>Quiz</strong></p>
                <p>
                  The quiz, based on Buzzfeed quizzes, is meant to act as an easy way 
                  for the user to get recommendations based on what they want and 
                  their experience with travelling. 
                </p>
              </li>
              <li>
                <p><strong>Planning & Tips Section</strong></p>
                <p>
                  The planning and tips section was made to reassure new users of 
                  what to bring and what to be aware of 
                  when planning for a trip. It can also serve as a packing reminder for more 
                  experienced users when they pack. 
                </p>
              </li>
              <li>
                <p><strong>Road Trip Planner</strong></p>
                <p>
                  The road trip planner can give users plans for their road trip by suggesting popular road trips or by giving 
                  the user the freedom to customize the trip as much as they want. It provides them with an easy road map and
                  an estimate of how long their trip will be.
                </p>
              </li>
            </ul>
          </div>
          <div className="space"></div>
        </div>
        <div className="my-5">
          <img className="exploreScreen" src={require("../img/explore_hifi1.png")} alt="Sketches of the different features, such as the quizzes and planning pages."/>
          <img className="exploreScreen" src={require("../img/explore_hifi2.png")} alt="Sketches of the different features, such as the quizzes and planning pages."/>
          <img className="exploreScreen" src={require("../img/explore_hifi3.png")} alt="Sketches of the different features, such as the quizzes and planning pages."/>
          <img className="exploreScreen" src={require("../img/explore_hifi4.png")} alt="Sketches of the different features, such as the quizzes and planning pages."/>
        </div>
        <div className="d-flex align-items-center">
          <div className="my-5">
            <h2 className="my-3">Next Steps</h2>
            <p>
              If given the opportunity to continue working on this, I would like 
              to expand the amount of activities that users can plan for. Hiking 
              and camping would be two next steps I would like to add.
            </p>
            <p>
              While the project was done in 3 hours, I would also like to improve 
              the UI to be more legible and intuitive.
            </p>
          </div>
          <div className="space"></div>
        </div>
      </div>
    );
  }
}

export default Explorer;
