import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Travelbud extends Component {
  render() {
    return (
      <div>
        <div className="cover-image tra-cover-image">
            <h1 className="tra-work-title">Travelbud</h1>
        </div>
        <div className="work-container">
          <Helmet>
              <title>Travelbud</title>
              <meta name="description" 
                content="Work page for Travelbud" 
              />
              <meta name="robots" content="index, follow" />
          </Helmet>
          <div>
            <blockquote className="proj-quote">
              <div className="mb-3 info">
                <br></br>Duration: 11 Weeks
                <br className="mt-2"></br>Role: UX Design, UI Design
                <br></br><a href="https://miro.com/app/board/o9J_kvUSUN8=/">User Flow</a>
                <br></br><a href="https://docs.google.com/spreadsheets/d/1RU4-jyDslGoHv8tBpJO76vN9Wcni_G7UwDl-6qizeoM/edit?usp=sharing">Intents, Slots, and Utterances</a>
                <br></br><a href="https://www.youtube.com/watch?v=KrEWRF6RFoY&feature=emb_title">Demo Video</a>
                <br></br><a href="https://docs.google.com/presentation/d/1sS34os9q0Kba9NmjmFrd_6wma6d2uteSJXT9-_ZgFn0/edit?usp=sharing">Travelbud Presentation Deck</a>
              </div>
              Travelbud is a voice assistant built with Voiceflow on Google Assistant. It's aim is to help low vision tourists travel around Seattle with ease. It can 
              give navigation through Google Maps, tell them about the area they're in, help them find restaurants, and also help with booking. 
            </blockquote>
            
          </div>
          
  
          <div className="d-flex align-items-center">
            <div className="my-5">
              <h2 className="my-3">What problems do people have with traveling with low vision?</h2>
              <p>
                People that travel with low vision can find it difficult to travel alone or without help. We wanted Travelbud to be able to help them so that they could feel more independent. 
                For people with low vision, reading signs from far away or small text on their phone screens can be difficult. The problem that we want to solve is for them to be able to travel 
                in cities more easily.
              </p>
  
              <div className="space"></div>
  
              <h2>Researching VUI conventions and user needs</h2>
              <p>
                We performed research on how to design user flows for voice assistants and also did research on voice assistant personas.
              </p>
              <p className="mt-2">
                We also did research on the types of low vision that people could have and how they get around. 
                As this was our first time creating a voice assistant, we relied heavily on our research and conventions about voice user interfaces.
              </p>
              <p className="mt-4">
                These were our key findings:
              </p>
              <p className="ml-3">
                <ol>
                  <li>
                    People with different types of low vision often have blurry or spotty vision, but they can still see large text and images.
                  </li>
                  <li>
                    Users prefer a female voice for voice assistants
                  </li>
                  <li>
                    To not overwhelm your user, only present a max of three choices to them
                  </li>
                  <li>
                    Tell the user what to say so they know how to respond
                  </li>
                </ol>
              </p>
  
              <h3 className="mt-5">Testing Travelbud</h3>
              <p>
                We performed usability tests among ourselves due to limited testing time. Testing Travelbud involved reading the dialogue out loud to each other
                to make sure it sounded good when voiced. Sometimes, dialogue looks good in text but it does not sound good when spoken, so we checked all of the dialogue for that. 
              </p>
              <p>
                The UI screens were tested on me because I have very blurry vision without eyeglasses. We made sure that I could read them when my glasses were removed
                and the screen was about 5 feet away.
              </p>
  
              <h3 className="mt-5">User Personas</h3>
              <p>
                  I created the user personas to get a better idea of what users of the system would want. The personas show two types of people that 
                  travel to Seattle and they both have a form of low vision.
              </p>
              <div className="img-container-travel">
                  <img className="flex-img-travel image-25" src={require("../img/josh.png")} alt="josh persona"/>
                  <img className="flex-img-travel image-25 ml-2" src={require("../img/jill.png")} alt="jill persona"/>
              </div>
              <p>
                  Josh is a younger user that has experience with traveling as he is a travel blogger. Jill is an older user of the app that is retired and 
                  has less travel experience and technology experience.
              </p>
              <p className="mb-3">
                  For the project, we focused on Josh but also made sure to keep Jill in mind when designing screens and the user flow.
              </p>
  
              <h3 className="mt-5">Travelbud's Persona</h3>
              <div className="center-container">
                <img className="my-3 image-75" src={require("../img/systempersona.png")} alt="system persona showing different attributes"/>
              </div>
              <p>
                  When creating the system persona for Travelbud, we relied on the research that we did to make decisions about how the system should act. We did not include an image
                  of the system that might anthropomorphize it to reduce chances of people associating the system with a picture of an actual human. We did want Travelbud 
                  to speak and sound like a human, however, so that users trust Travelbud.
              </p>
              <p>
                  We followed the research when deciding to use a female voice. Most voice assistants are female due to various reasons so we wanted to follow that convention. When 
                  creating the dialogs we also made sure to manually add in pauses and breaks to make Travelbud sound more human.
              </p>
  
              <h3 className="mt-5">Creating a customer journey map</h3>
              <div className="center-container">
                <img className="cjm" src={require("../img/cjm.png")} alt="system persona showing different attributes"/>
              </div>
              <p>
                  Once the personas were made, a customer journey map of Josh's trip to Seattle was made. We wanted to see how his trip would go and plan out how he could use Travelbud
                  in each part of his trip. Doing this made it easier to organize the different things that Travelbud could do to help Josh in each section of his trip.
              </p>
            </div>
            <div className="space"></div>
          </div>
  
          <div className="align-items-center">
            <div className="my-5">
              <h2>Figuring out the intents, slots, and utterances</h2>
              <p>
                An important part of designing a voice assistant is documenting all the possible needs that users can have with the assistant, what they say to let the assistant know their need, and what the system says in response.
                These are known as intents and utterances. Slots are how the system better interprets what the users says.
              </p>
              <p className="mt-2">
                <ul>
                  <li className="list-unstyled"><strong>Intent</strong> - What the user wants to achieve, their objective.</li>
                  <li className="list-unstyled"><strong>Utterance</strong> - What the user actually says to get their intent or what the system says in response to the user.</li>
                  <li className="list-unstyled"><strong>Slot</strong> - Like a variable, a way to capture what the user said in their utterance. Systems use slots to help the user.</li>
                </ul>
              </p>
              
              <p>
                Example of a user asking for directions.
              </p>
              <blockquote className="ml-3 mt-3">
                  User Utterance: Travelbud, can you show me how to get to Pike Place Market?
                  <br></br><br></br>Travelbud (System Utterance): Yes, I will start the navigation at your current location.
              </blockquote>
              <p>
                In this example, the intent would be something like <strong>GetDirectionsIntent</strong>.
                <br></br>The slot would be <strong>{'{'}destination{'}'}</strong> and the value of the slot would be <strong>'Pike Place Market'</strong>.
              </p>
              
              <p className="mt-5">
                We brainstormed possible intents that users could have with Travelbud and put them into an excel sheet, which can be viewed 
                <a className="link" href="https://docs.google.com/spreadsheets/d/1RU4-jyDslGoHv8tBpJO76vN9Wcni_G7UwDl-6qizeoM/edit?usp=sharing"> here</a>.
              </p>
              
              <div className="space"></div>
  
              <h2>Creating the user flows</h2>
              <div className="center-container">
                <img className="image-75 my-3" src={require("../img/userflow.jpg")} alt="info page of pike place"/>
              </div>
              <p>
                With the customer journey map and the intents, slots, and utterances document finished, we used them to create the main user flow of Travelbud. We separated the flow into six different flows.
              </p>
              <p>
                The screens that go along with each user flow were designed to complement the voice and were meant to be easy to read for low vision users. Most of the screens have an option on them to adjust the
                text size, and they were designed as if the size was set to the biggest option.
              </p>
              
              <h3 className="mt-5">General User Flow</h3>
              <p>
                General is the main user flow that users are directed to when they start Travelbud for the first time or are welcomed back to the app. General connects all other user flows together based on what the user says.
                Users can start here, but if their utterance is very specific, then they can start at other user flows.
              </p>
              <div className="center-container">
                <img className="image-50 my-3" src={require("../img/welcome.png")} alt="Travelbud welcome"/>
              </div>
              
              <h3 className="mt-5">Planning</h3>
              <p>
                Planning is all about creating a plan for a user's trip. Users can ask Travelbud for information about Seattle or information about tourist areas or other places. If a user is interested, they can add
                the place to their plan, which Travelbud manages for them.
              </p>
              <div className="img-container-travel">
                  <img className="flex-img-travel image-25" src={require("../img/spots1.png")} alt="tourist spots in seattle"/>
                  <img className="flex-img-travel image-25 ml-2" src={require("../img/spots2.png")} alt="tourist spots in seattle"/>
              </div>
              
              <h3 className="mt-5">Booking</h3>
              <p>
                Travelbud can book various things for users such as hotels, tours, museums, or restaurants. This is useful for people that are busy or have trouble doing it themselves on a screen.
                Travelbud goes through the process with the user to make it easy for them, and it can use saved information such as name, address, and card information to make the booking even faster.
              </p>
              <div className="center-container">
                <img className="image-50 my-3" src={require("../img/bookinghotel.png")} alt="booking a hotel at the hilton"/>
              </div>
              
              <h3 className="mt-5">Traveling</h3>
              <p>
                Once users know where they want to go, Travelbud can lead them to the right place. As Travelbud is built with Google Assistant, it can use Google Maps, which already has voice directions.
                All users have to do is confirm their destination and location and the navigation can begin.
              </p>
              <div className="center-container">
                <img className="image-50 my-3" src={require("../img/map.png")} alt="map navigation"/>
              </div>
  
              <h3 className="mt-5">Exploring & Dining</h3>
              <p>
                When users are walking around Seattle out and about, they can ask Travelbud for information about the area they are in for more information. Local history, popular spots, and nearby restaurants are
                all available for the user to know about.
              </p>
              <div className="center-container">
                <img className="image-50 my-3" src={require("../img/exploringpike.png")} alt="info page of pike place"/>
              </div>
  
              <h3 className="mt-5">Sharing Experiences</h3>
              <p>
                Travelbud can enable users to share their favorite travel moments with their loved ones or on social media and it is accssible by just voice, with screens to help the user choose the right media to share.
                Users can share the media to contacts or to social media that they are already logged into. Travelbud takes care of the caption, tags, and more.
              </p>
              <div className="img-container-travel">
                  <img className="flex-img-travel image-25" src={require("../img/selectimage.png")} alt="selecting an image to share"/>
                  <img className="flex-img-travel image-25 ml-2" src={require("../img/shareimage.png")} alt="choosing where to share image"/>
              </div>
  
              <p className="mt-3">
                The full user flow document can be found <a className="link" href="https://miro.com/app/board/o9J_kvUSUN8=/">here</a>.
              </p>
              
              <div className="space"></div>
              
              <h2>Demo Video</h2>
              <p>
                To show how users would interact with Travelbud, we created an example user flow of a user planning a trip to Seattle and booking a tour through Travelbud.
              </p>
              <p>
                The dialogue, built in Voiceflow, can be seen in this <a className="link" href="https://www.youtube.com/watch?v=KrEWRF6RFoY&feature=emb_title">video</a>.
              </p>
            </div>
            <div className="space"></div>
          </div>
          
          <div className="align-items-center">
            <div className="my-5">
              <h2 className="my-3">Project Next Steps & Lessons Learned</h2>
              <p className="mt-3">
                Many of the planned user flows that we had for Travelbud were made in a flowchart and only a small section was created in Voiceflow, so future work for this
                would involve creating the rest of the dialog in Voiceflow. Travelbud could also be expanded to work on mobile devices when exported to Google Assistant.
              </p>
              <p className="mt-5">
                This project taught me a lot about designing screens for voice-first systems and how to design voice-first systems in the first place. 
                These are the skills I learned by doing this project:
              </p>
              <p className="mt-4 ml-4">
                <ul>
                  <li>Designing user flows for systems</li>
                  <li>How to make system personas</li>
                  <li>How to use Voiceflow</li>
                  <li>What goes into designing voice assistants, such as the user flows, general rules about voice assistants, and more</li>
                </ul>
              </p>
            </div>
            <div className="space"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Travelbud;
