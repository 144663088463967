import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Solstice extends Component {
  render() {
    return (
      <div>
        <div className="cover-image sol-cover-image">
          <h1 className="sol-work-title">Solstice</h1>
        </div>
        <div className="work-container">
          <Helmet>
              <title>Solstice</title>
              <meta name="description" 
                content="Work page for Solstice" 
              />
              <meta name="robots" content="index, follow" />
          </Helmet>
          <div>
            <blockquote className="proj-quote">
              Solstice is an operating system for mobile phones that is centered around balance and function. It was made to help users
              be mindful about their interactions with their phone and create more meaningful interactions. This project was done in a team of 8,
              with each person being involved in designing applications for the OS.
              <div className="mt-1 info">
                <br></br>Duration: 11 Weeks
                <br className="mt-2"></br>Role: UX Design, UI Design
                <br></br><a href="https://drive.google.com/file/d/16-KXzz4LI19YwYIvfFnq6SGwc5sKyi5F/view?usp=sharing">Solstice Presentation Slide Deck</a>
              </div>
            </blockquote>
            
          </div>
          
          <div className="d-flex align-items-center">
            <div className="my-5">
              <h2 className="my-3">What problem does this OS aim to solve?</h2>
              <p>
                Before we could begin designing apps, we had to create a design language for our OS that would solve a problem.
                Solstice's design language was made to answer the problems with addiction in today's technology use, particularly smartphones.
              </p>
  
              <h3 className="mt-4">Why the topic of mindfulness?</h3>
              <p>
                We chose topic because we disliked the current trend of apps featuring dark UX patterns that tricked users into spending money
                or wasting time. Members of the group also felt like they spent too much time on their phones. We felt strongly about those types of designs and 
                disliked them, which is why we wanted to create designs that countered them.
              </p>
              <div className="space"></div>
              <h2 className="mt-5">User Research and Findings</h2>
              <p>
                We decided to perform research to find out exactly what problem with smartphones we should tackle.
                We conducted interviews with smartphone users and performed a literature review and through them, we found problems with today's smartphone usage. 
              </p>
              
              <div className="quote-container mt-5">
                <div className="w-50">
                  <blockquote className="center">
                    “Social apps have a stable, consistent, and thoroughly addicted user base, with 50 percent of people engaging with social networks for more than 5 hours a day”
                  </blockquote>
                  <p className="quote-source">
                    <a href="https://www.vox.com/2014/3/6/11624264/mixpanel-how-addictive-is-your-app">Mixpanel, Analytics Company</a>
                  </p>
                </div>
              </div>
  
              <div className="quote-container">
                <div className="w-50">
                    <blockquote className="center">
                      "The mere presence of their smartphone is enough to reduce cognitive capacity.”
                    </blockquote>
                    <p className="quote-source">
                      <a href="https://news.utexas.edu/2017/06/26/the-mere-presence-of-your-smartphone-reduces-brain-power/">UT Austin Study</a>
                    </p>
                </div>
              </div>
  
              <p className="mt-5">
                We turned our focus on solving these two problems in the applications we designed.
              </p>
              <p className="mt-1">
                <ul>
                  <li className="list-unstyled"><strong>Distraction </strong>- Defined as an object that prevents someone from giving their full attention to something else</li>
                  <li className="list-unstyled"><strong>Information Overload </strong>- An excessive load or amount of information</li>
                </ul>
              </p>
              <div className="space"></div>
              <h2 className="mt-4">Design Language Statement</h2>
              <p>
                This is the design language statement for Solstice, which we kept in mind while we designed applications for the OS.
              </p>
              
              <div className="quote-container mt-5">
                <div className="w-50">
                  <p className="statement">
                    Solstice helps you reach your <colour>high points</colour> and supports you through your <colour>low points</colour>. It promotes <colour>healthy interactions</colour> with your device.
                  </p>
                </div>
              </div>
  
            </div>
          </div>
          <div className="align-items-center">
            <div className="my-5">
              <h2 className="my-3">Establishing the UX and UI</h2>
              <p>
                While some of the group started on brainstorming application ideas and features, some of us began to discuss the UX guidelines for our apps. We looked at things such as
                line spacing, icon sizes, and text size.
              </p>
              
  
              <h3 className="mt-5">General Spacing</h3>
              <p>
                We looked at screens of iOS and Android applications to see what their general measurements for spacing and icon sizing were. The following are the UX guidelines we wanted our apps to have:
              </p>
              <p className="mt-2">
                
                  <dl>
                    <strong><dt>There should be at least 16px of spacing between items</dt></strong>
                      <dd>Requiring this allows for ample spacing so there will be no overcrowding</dd>
                    <strong><dt>Items should be at least 24px away from the screen edges</dt></strong>
                      <dd>Much like the reason above, but ensures that content is not too close to the edges of the screen</dd>
                    <strong><dt>Icons should be 24px</dt></strong>
                      <dd>Allows icons to be big enough to tap, including the spacing around it</dd>
                  </dl>     
              </p>
  
              <h3 className="mt-5">List Item Spacing</h3>
              <div className="center-container">
                <img className="my-3" src={require("../img/lineitemsize.png")} alt="shows different line sizes"/>
              </div>
              <p>
                One of the aspects of our apps that we had the most trouble with was how to display the content.
                We decided to have all of our apps that use list items use the same template
                which features ample space to lower any potential information overload for the user.
              </p>
              
              <h3 className="mt-5">Typography</h3>
              <p>
                For our font, we chose Avenir Next as we wanted a modern sans-serif font that was also easy to read no matter the font size.
              </p>
              <div className="center-container">
                <img className="image-75" src={require("../img/solstext.png")} alt="the font sizes we chose for solstice"/>
              </div>
            
              <h3 className="mt-5">The Design Process</h3>
              <p>
                When designing the apps for our OS, this was the usual process we went through:
              </p>
              <p className="ml-4">
                <ol>
                  <li>Look at other comparable apps for inspiration or ideas</li>
                  <li>Brainstorm design ideas with others, including ways to promote mindfulness</li>
                  <li>Sketch out ideas</li>
                  <li>Build out a prototype</li>
                </ol>
              </p>
            </div>
            <div className="space"></div>
          </div>
  
          <div className="align-items-center">
            <div className="mt-3">
  
              <h2>Lockscreen & Springboard</h2>
  
              <div className="center-container">
                <img className="smallScreen2" src={require("../img/oldlockscreen.png")} alt="screenshots of the old lockscreen"/>
              </div>
              <p>
                This was our first iteration of the lockscreen where we tested different ways of showing notifications and using apps from the lockscreen.
              </p>
              <h3 className="mt-5">Final Version</h3>
              <div className="center-container">
                <img className="smallScreen2" src={require("../img/springlock.png")} alt="screenshots of the lockscreen and springboard"/>
              </div>
              <p>
                For the lockscreen and springboard, we designed them to have features that would help users control the amount of notifications
                they get and control how much they can use each app.
              </p>
              <h3 className="mt-5">Lockscreen Features</h3>
              <div className="gif-content">
                <p className="mt-2 text-side">  
                    <dl>
                      <strong><dt>Notifications are hidden behind the application's name</dt></strong>
                        <dd>Users will have to tap the notification to see them so it's an extra step, but this ensures that the interaction is intentional and does not cause a distraction.</dd>
                      <strong><dt>Soft Launch Feature</dt></strong>
                        <dd>Users can launch their chosen apps directly from their lockscreen, such as Messages or Phone without having to unlock their phone.
                        By being able to access apps there, users will face less distractions than they will when they unlock their phones.</dd>
                        <dd>Users could potentially place a distracting app there, but we rely on the assumption that our users want to be intentional in their mindfulness.</dd>
                    </dl>   
                </p>
                <img className="gif-side" src={require("../img/lockscreengif.gif")} alt="gif showing interactions in the lockscreen"/>
              </div>
              <h3 className="mt-5">Springboard Features</h3>
              <div className="gif-content">
                <img className="gif-side-l" src={require("../img/springboardgif.gif")} alt="gif showing interactions in the springboard"/>
                <p className="mt-2 text-side">
                  The springboard was made to be similar to Android in order to provide an easy way for users to learn how to navigate the OS.
                  <dl>
                      <strong><dt>Widgets</dt></strong>
                        <dd>Widgets are available for the user to use, such as the weather and notes app. Widgets can be changed to the user's liking.</dd>
                      <strong><dt>Application Lock Feature</dt></strong>
                        <dd>On the springboard users can tap and hold on an app and choose to lock them, which will prevent them from using it for a set amount of time.
                        Users can use this feature to prevent themselves from using potentially distracting apps.</dd>
                    </dl>   
                </p>
              </div>
              <div className="space"></div>
              
  
              <h2>Messages</h2>
              
              <p>
                Our initial ideas for messages were to keep it like most modern messaging apps. We were mostly concerned with the app's visuals at the beginning.
                We wanted to use cards, but that would make it harder for our other apps to match as some could not use cards so we decided to abandon cards and go with list items to keep the apps consistent.
              </p>
              <div className="center-container">
                <img className="smallScreen" src={require("../img/oldmessages.png")} alt="screenshots of the old messages app"/>
              </div>
              <h3 className="mt-5">Final Version</h3>
              <div className="center-container">
                <img className="smallScreen2 mt-3" src={require("../img/messages.png")} alt="screenshots of the messages app"/>
              </div>
              <h3 className="mt-5">Features</h3>
  
              <div className="gif-content">
                <p className="mt-2 text-side">  
                    <dl>
                      <strong><dt>Pinned Threads</dt></strong>
                        <dd>Users can pin message threads at the top of the app by dragging them to the designated area. By providing this feature, users can pin their
                        favorite contacts so that they are always at the top. This lowers any distractions for users if they only want to see messages from certain people.</dd>        
                    </dl>   
                </p>
                <img className="gif-side" src={require("../img/messagesgif.gif")} alt="gif showing interactions in messages"/>
              </div>
              <div className="mt-5 gif-content">
                <img className="gif-side-l" src={require("../img/pinmessagesgif.gif")} alt="gif showing interactions in messages"/>
                <p className="mt-2 text-side">  
                <dl>
                  <strong><dt>Pinned Messages</dt></strong>
                        <dd>Inside of message threads, users can also pin individual messages which can be seen in the pinned messages tab. This is an extra feature that users can use
                        for important messages so that they can see them whenever they wish.</dd>
                </dl>
                </p> 
              </div>
              
              <div className="space"></div>
              
              <h2>Mail</h2>
  
              <p>
                For our first time designing the mail app, we looked at the iOS mail app and Gmail. We primarily took inspiration from Gmail but we wanted to make mail
                have more of an ability for the user to categorize their mail and also be less overwhelmed when using the app.
              </p>
              <div className="center-container">
                <img className="smallScreen" src={require("../img/oldmail.png")} alt="screenshots of the old mail app"/>
              </div>
              <h3 className="mt-5">Final Version</h3>
              <div className="center-container">
                <img className="smallScreen2 mt-3" src={require("../img/mail.png")} alt="screenshots of the mail app"/>
              </div>
              <h3 className="mt-5">Features</h3>
              <div className="gif-content">
                <p className="mt-2 text-side">  
                    <dl>
                      <strong><dt>Reduced Visual Clutter</dt></strong>
                        <dd>Many email inboxes can stress people due to the amount of unread emails, and we wanted to reduce that stress and reduce visual clutter. Unread messages only bold the subject line
                        while every other line stays unbolded. We also added a blue dot to the right of the message as another way to indicate a message was unread.</dd>   
                      <strong><dt>Increased Categorization</dt></strong>
                        <dd>User can swipe left and right to switch between primary, unread, and junk mail. If they swipe left, they can see other categorization for their mail, such as Career and Social.
                        Extra categorization was added to reduce information overload by having users only see what they want to see.</dd>   
                    </dl>   
                </p>
                <img className="gif-side" src={require("../img/mailgif.gif")} alt="gif showing interactions in mail"/>
              </div>
              <div className="space"></div>
  
              <h2>Weather</h2>
              
              <p>
                When beginning to design this app, we looked at other weather apps and noticed that they had a lot of extra information such as UV index and humidity.
                The information is useful but we asked people what information they looked at when they opened the weather app and found that people mostly look for these:
              </p>
              <div className="center-container">
                <img className="smallScreen2" src={require("../img/oldweather.png")} alt="screenshots of the weather app"/>
              </div>
              <p className="mt-3 ml-4">
                <ol>
                  <li>Current Weather</li>
                  <li>Precipitation Chance</li>
                  <li>Temperature</li>
                </ol>
              </p>
              <p>
                So for our app, we focused on displaying those three things to the user. Other weather related information would still be available but by default
                it would not be displayed.
              </p>
              <h3 className="mt-5">Final Version</h3>
              <div className="center-container">
                <img className="smallScreen2" src={require("../img/weather.png")} alt="screenshots of the weather app"/>
              </div>
              <h3 className="mt-5">Features</h3>
              <div className="gif-content">
                <p className="mt-3 text-side">
                  We designed the home screen to be simple and give information at a glance. The background shows the current weather, the current temperature, rain chance, and the weather
                  forecast for the next hour. <br></br>If users want to see more, they can swipe up to see a chart showing precipitation chance over the next few hours. Below, they can also see
                  weather forecasts for the next few days. Different locations can be seen with a left swipe. 
                </p>
                <img className="gif-side" src={require("../img/weathergif.gif")} alt="gif showing interactions in weather"/>
              </div>
            </div>
          </div>
          
          <div className="align-items-center">
            <div className="my-5">
              <div className="space"></div>
              <h2 className="my-3">Next Steps and Lessons Learned</h2>
              <p className="mt-3">
                If I were to continue to work on this project, I would continue to design apps for the OS that follow the design language or helping users
                be more mindful about their smartphone usage. Being able to tackle the problem would require more a more significant overhaul in 
                OS design and I would like to try and tackle that.
              </p>
              <p className="mt-5">
                Doing this project taught me much about design. 
                These are the skills I both learned and reinforced by doing this project:
              </p>
              <p className="mt-4 ml-4">
                <ul>
                  <li>UX guidelines for icon and font sizes</li>
                  <li>How to work as a deigner in a team of other deisgners</li>
                  <li>Taking inspiration from other apps</li>
                  <li>How to keep designs consistent across a team</li>
                  <li>Creating an asset library</li>
                  <li>Creating UX guidelines</li>
                </ul>
              </p>
            </div>
            <div className="space"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Solstice;
